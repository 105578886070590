import {createGlobalStyle} from 'styled-components';

const GlobalStyle = createGlobalStyle`

// Font imports
@import url(
    'https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700&display=swap'
);


* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    background: #f1f1f1;
}

h1 {
    font-family: 'Bodoni', serif;
    font-weight: normal;
}

h2 {
    font-family: 'Lato', sans-serif;
    font-weight: normal;
    letter-spacing: 0.25rem;
}

h3, p, li {
    font-family: 'Lato', sans-serif;
    font-weight: normal;
}

button {
    font-family: 'Lato', sans-serif;
    font-weight: bold;
}`

export default GlobalStyle;
