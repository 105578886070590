import React from 'react';
import TeamImg from '../../assets/Yritys.jpg';
import './About.css';

import { motion } from "framer-motion";
import { fromBelowAnim } from "../../Animations";

const About = () => {
    return (
        <div id="About">
            <div className="about-container">
                <div className="image-section">
                    <img src={TeamImg} alt="Team"/>
                </div>
                <div className="about-section">
                    <motion.div 
                        variants={fromBelowAnim} 
                        initial="hidden"
                        whileInView="show"
                        viewport={{ once: true }}
                        className="Otsikkodiv-Yrityksemme"
                        >
                            <h2>Yrityksemme</h2>
                    </motion.div>
                        <p> 
                            Keski-Suomessa sijaitseva GrafiTeam Oy tarjoaa erilaisia tulostus- ja painopalveluita.
                            Nykyaikaiset laitteet takaavat laadukkaan, kestävän ja kustannustehokkaan lopputuloksen.
                            Palveluihin kuuluvat myös mainoskylttien asennukset, erilaiset teippaukset, graafinen
                            suunnittelu sekä tiedostojen, pakkausten ja myyntitelineiden valmistus.
                        </p>
                        <p>
                            Teemme yhteistyökumppaneillemme myös alihankintatulostusta. Käytössämme olevat uv-tulostimet
                            mahdollistavat jopa 3,2 metrin maksimitulostusleveyden lähestulkoon kaikille materiaaleille!
                        </p>
                </div>
            </div>
        </div>
    );
}
 
export default About;
