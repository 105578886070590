import React from "react";
import "./FrontPage.css";

// Components 
import Nav from "../Nav/Nav";

//Images
import grafiImage from "../../assets/Grafiteam_background.png";

// Animations
import { motion } from "framer-motion";
import { titleAnim, imageAnim } from "../../Animations";

const FrontPage = () => {
    return (
        <div className="frontpage">
            <Nav/>
            <div className="frontpage-container">
                <motion.div 
                    variants={titleAnim} 
                    initial="hidden"
                    animate="show"
                    className="frontpage-text"
                >
                    <div className="frontpage-title">
                        <h1>Kerää katseita!</h1>
                        <motion.h3 variants={titleAnim}>
                            Meiltä saat printtituotteet käyntikorteista tienvarsimainoksiin.
                        </motion.h3>
                    </div>
                    <motion.div 
                        variants={titleAnim}
                        className="ctas"
                    >
                        <button className="cta-main" onClick={() => {window.location.href='#Contact';}}>Ota yhteyttä</button>
                    </motion.div>
                </motion.div>
                <motion.div
                     variants={imageAnim} 
                     initial="hidden"
                     animate="show"
                     className="frontpage-image">
                    <img src={grafiImage} alt="Grafiteam" />
                </motion.div>
            </div>
        </div>
    );
}
 
export default FrontPage;
