import React, { useState } from "react";
import "./Nav.css";

// Animations
import { motion } from "framer-motion";
import { navAnim } from "../../Animations";

// For logo import
import Logo from "../../assets/GrafiTeam_logo.png";

// Social media icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram, faFacebook } from "@fortawesome/free-brands-svg-icons";

const Nav = () => {

    // Mobile/burger menu toggle
    const [ active, setActive ] = useState("nav-links");
    const [ toggleIcon, setToggleIcon ] = useState("burger-menu");

    const navToggle = () => {
        active === "nav-links"
            ? setActive("nav-links nav-active")
            : setActive("nav-links");

        // Toggle Animation
        toggleIcon === "burger-menu" 
            ? setToggleIcon('burger-menu toggle')
            : setToggleIcon('burger-menu');
    };
    
    return (
        <motion.nav 
            variants={navAnim} 
            initial="hidden"
            animate="show"
            className="navbar"
        >
            <div className="logo">
                <img src={Logo} className="logo" alt="Logo" />
            </div>
            <ul onClick={navToggle} className={active}>
                <li>
                    <a href="#Services">Palvelut</a>
                </li>
                <li>
                    <a href="#About">Yritys</a>
                </li>
                <li>
                    <a href="#Contact">Ota yhteyttä</a>
                </li>
                <div className="socmedia">
                    <li>
                        <a href="https://www.instagram.com/grafiteam.fi" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon 
                                icon={faInstagram} 
                                color="#fff" 
                                fontSize="30px"
                            />
                        </a>
                    </li>
                    <li>
                        <a href="https://www.facebook.com/grafiteamoy/" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon 
                                icon={faFacebook} 
                                color="#fff" 
                                fontSize="30px"
                            />
                        </a>
                    </li>
                </div>
            </ul>
            <div onClick={navToggle} className={toggleIcon}>
                <div className="line1"></div>
                <div className="line2"></div>
                <div className="line3"></div>
            </div>
        </motion.nav>
    );
}
 
export default Nav;
