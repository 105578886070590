import React from 'react';

// Global style
import GlobalStyle from './components/GlobalStyle';

// Components
import FrontPage from './components/FrontPage/FrontPage';
import Palvelut from './components/Services/Palvelut';

function App() {
  return (
    <div>
      <GlobalStyle/>
      <FrontPage/>
      <Palvelut/>
    </div>
  );
}

export default App;
