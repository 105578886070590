import React from 'react';
import './Asiakkaat.css';

import Rapala from "../../assets/Rapala_logo.png";
import Sika from "../../assets/Sika_logo_rgb.png";
import Arvid from "../../assets/ArvidNordquist_logo.png";
import Valio from "../../assets/Valio_logo.png";
import Olvi from "../../assets/Olvi-logo.png"; 

// Animations
import { motion } from "framer-motion";
import { fromBelowAnim, fromBelowAnim2 } from "../../Animations";

const Asiakkaat = () =>{

    return(
        <div className="Clients" id="Clients">
            <motion.div 
                    variants={fromBelowAnim} 
                    initial="hidden"
                    whileInView="show"
                    viewport={{ once: true }}
                    className="Otsikkodiv-Asiakkaitamme"
            >
                <h2 className="Otsikko" id="Asiakkaitamme" >ASIAKKAITAMME</h2>
            </motion.div>
            <div className="flex-this">
                <motion.div 
                      variants={fromBelowAnim2}  initial="hidden" whileInView="show" viewport={{ once: true }}
                      className="asiakaslogo"
                ><img src={Rapala} alt="Rapala" className="clientpic"/>
                </motion.div>
                <div className="eierotetajosmahd">
                    <motion.div 
                        variants={fromBelowAnim}  initial="hidden" whileInView="show" viewport={{ once: true }}
                        className="asiakaslogo"
                    ><img src={Sika} alt="Sika Finland" className="clientpic"/>
                    </motion.div>
                    <motion.div 
                        variants={fromBelowAnim2}  initial="hidden" whileInView="show" viewport={{ once: true }}
                        className="asiakaslogo"
                    ><img src={Arvid} alt="ArvidNordquist" className="clientpic"/>
                    </motion.div>
                </div>
                <div className="eierotetajosmahd">
                    <motion.div 
                        variants={fromBelowAnim2}  initial="hidden" whileInView="show" viewport={{ once: true }}
                        className="asiakaslogo"
                    ><img src={Valio} alt="Valio" className="clientpic"/>
                    </motion.div>
                    <motion.div 
                        variants={fromBelowAnim}  initial="hidden" whileInView="show" viewport={{ once: true }}
                        className="asiakaslogo"
                    ><img src={Olvi} alt="Olvi" className="clientpic"/>
                    </motion.div>
                </div>
            </div>
        </div>
    )
}

export default Asiakkaat;
