import React, {useState} from 'react';
import './Palvelut.css';
import Gallery from '../Gallery/Gallery';
import Asiakkaat from '../Clients/Asiakkaat';
import Kaavake from '../Contact/kaavaketta';
import About from '../About/About';

import Banderolli from '../../assets/banderollit_ja_mainoskyltit.png'
import Myymälämainokset from '../../assets/myymalamainokset.png'
import Myyntitelineet from '../../assets/myyntitelineet.png'
import Printtituotteet from '../../assets/printtituotteet.png'
import Teipit_ja_tarrat from '../../assets/teipit_ja_tarrat.png'
import Tienvarsimainokset from '../../assets/tienvarsimainokset.png'

// Animations
import { motion } from "framer-motion";
import { fromBelowAnim, fromBelowAnim2 } from "../../Animations";

const Palvelut = () =>{

  const [active, setActive] = useState(true);
  const [showingGallery, setShowingGallery ] = useState("showGallery");
  const [showingGalleryBox, setShowingGalleryBox ] = useState("galleryBox");
  const [sivunalaosa, setSivunalaosa] = useState("alaosaupwards");
  const [firstload, setFirstload] = useState("hidden");

  const handleClick = () => {
      setActive(!active);
      showingGallery === "showGallery" 
        ? setShowingGallery('showGallery toggled')
        : setShowingGallery('showGallery');
      showingGalleryBox === "galleryBox" 
        ? setShowingGalleryBox('galleryBox enlarged')
        : setShowingGalleryBox('galleryBox');
      sivunalaosa === "alaosaupwards" 
        ? setSivunalaosa('toggledalaosa')
        : setSivunalaosa('alaosaupwards');
      setFirstload("show");
    };

  const Tuotteet = () => {
    return(
        <div id="Services" className="Services">
            <motion.div 
                    variants={fromBelowAnim} 
                    initial={firstload}
                    whileInView="show"
                    viewport={{ once: true }}
                    className="Otsikkodiv-Tuotteet"
            >
                <h2 className="Otsikko" id="Palvelumme">Palvelumme</h2>
            </motion.div>
            <div className="grid-container">
                <motion.div 
                      variants={fromBelowAnim2}  initial={firstload} whileInView="show" viewport={{ once: true }}
                ><img src={Banderolli} className="kuva" alt="Banderolli"></img>
                 <p className="p">Banderollit ja mainoskyltit</p>
                </motion.div>
                <motion.div 
                      variants={fromBelowAnim}  initial={firstload} whileInView="show" viewport={{ once: true }}
                ><img src={Myymälämainokset } className="kuva2" alt="Myymälämainokset"></img>
                 <p className="p">Myymälämainokset</p>
                </motion.div>
                <motion.div 
                      variants={fromBelowAnim2}  initial={firstload} whileInView="show" viewport={{ once: true }}
                ><img src={Myyntitelineet} alt="Myyntitelineet" className="kuva1"></img>
                 <p className="p">Myyntitelineet</p>
                </motion.div>
                <motion.div 
                      variants={fromBelowAnim}  initial={firstload} whileInView="show" viewport={{ once: true }}
                ><img src={Printtituotteet} alt="Printtituoteet" className="kuva"></img>
                 <p className="p">Printtituotteet</p>
                </motion.div>
                <motion.div 
                      variants={fromBelowAnim2}  initial={firstload} whileInView="show" viewport={{ once: true }}
                ><img src={Teipit_ja_tarrat} alt="Teipit ja tarrat" className="kuva1"></img>
                 <p className="p">Teipit ja tarrat</p>
                </motion.div>
                <motion.div 
                      variants={fromBelowAnim2}  initial={firstload} whileInView="show" viewport={{ once: true }}
                ><img src={Tienvarsimainokset} alt="Tienvarsimainokset" className="kuva"></img>
                 <p className="p">Tienvarsimainokset</p>
                </motion.div>
            </div>
            <motion.div 
                variants={fromBelowAnim2}  initial={firstload} whileInView="show" viewport={{ once: true }}
                id="Gallery"
                className="buttonfoundhere"
            >
                <button onClick={handleClick} className="simsalabimnappi">
                        {active
                          ? "Katso Kuvia"
                          : "Piilota Kuvat"
                        }
                </button>
            </motion.div>
        </div>
    )
  }

  return(
    <div>
      <Tuotteet/>
      <div className="galleryHideHelper">
        <div className={showingGallery}> {/* always 'showGallery' and once the button is triggered also 'toggled' */}
          <Gallery/>
        </div>
      </div>
      <div className={sivunalaosa}> {/* toggled between 'alaosaupwards' and 'toggledalaosa' */}
          <Asiakkaat/>
          <About/>
          <Kaavake/>
      </div>
    </div>
  )
}

  export default Palvelut
