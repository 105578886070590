/* ---- Frontpage Animations ----- */

 export const titleAnim = {
    hidden: {
        opacity: 0, 
        x: 20,
    },
    show: {
        opacity: 1, 
        x: 0,
        transition: {
            duration: 1.5,
            staggerChildren: 1,
        }
        
    }
};

export const imageAnim = {
    hidden: {
        opacity: 0,
    },
    show: {
        opacity: 1,
        transition: {
            duration: 2.5
        }
    }
}

/* ------- Nav animations ------- */

export const navAnim = {
    hidden: {
        opacity: 0
    },
    show: {
        opacity: 1, 
        transition: {
            duration: 1,
            transform: "ease-in",
        }
    },
};

/* ------- Lower Down the Page animations ------- */

export const fromBelowAnim = {
    hidden: {
        opacity: 0,
        y: 50
    },
    show: {
        opacity: 1, 
        y: 0,
        transition: {
            duration: 0.3,
            transform: "ease-in",
        }
    },
};

export const fromBelowAnim2 = {
    hidden: {
        opacity: 0,
        y: 70
    },
    show: {
        opacity: 1, 
        y: 0,
        transition: {
            duration: 0.4,
            transform: "ease-out",
        }
    },
};

export const fromBelowAnim3 = {
    hidden: {
        opacity: 0,
        y: 200
    },
    show: {
        opacity: 1, 
        y: 0,
        transition: {
            duration: 0.35,
            delay: 0.1,
            transform: "ease-in",
        }
    },
};

export const fromBelowAnim4 = {
    hidden: {
        opacity: 0,
        y: 200
    },
    show: {
        opacity: 1, 
        y: 0,
        transition: {
            duration: 0.4,
            delay: 0.25,
            transform: "ease-in",
        }
    },
};

export const fromBelowAnim5 = {
    hidden: {
        opacity: 0,
        y: 200
    },
    show: {
        opacity: 1, 
        y: 0,
        transition: {
            duration: 0.5,
            delay: 0.4,
            transform: "ease-out",
        }
    },
};

export const scrollReveal = {
    hidden: {
        opacity: 0,
        x: 350
    },
    show: {
        opacity: 1, 
        x: 0,
        transition: {
        }
    },
};
