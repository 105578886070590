import Gallery1 from '../../assets/galleriacasco.png'
import Gallery2 from '../../assets/galleriaerisan.png'
import Gallery3 from '../../assets/galleriahyllypuhuja.png'
import Gallery4 from '../../assets/galleriakaivinkone.png'
import Gallery5 from '../../assets/galleriakrauta.png'
import Gallery6 from '../../assets/gallerialattiatarra.png'
import Gallery7 from '../../assets/galleriamaantietaulu.png'
import Gallery8 from '../../assets/galleriapakettiauto.png'
import Gallery9 from '../../assets/galleriapopup.png'
import Gallery10 from '../../assets/galleriarapala.png'
import Gallery11 from '../../assets/galleriasahkokontti.png'
import Gallery12 from '../../assets/galleriavene.png'
import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

import './Gallery.css';

export default function CarouselComponent() {
    return (
        <div className="carousel-wrapper">
        <div style={{ maxWidth: 600, marginLeft: 'auto', marginRight: 'auto', marginTop: 20 }}>
            <Carousel infiniteLoop useKeyboardArrows autoPlay>
            
                <div>
                    <img src={Gallery1} alt="Casco"/>
                </div>
                <div>
                    <img  src={Gallery2} alt="Erisan"/>
                </div>
                <div>
                    <img src={Gallery3} alt="Hyllypuhuja" />
                </div>
                <div>
                    <img src={Gallery4} alt="Kaivinkone" />
                </div>
                <div>
                    <img src={Gallery5} alt="Krauta"/>
                </div>
                <div>
                    <img src={Gallery6} alt="Lattiatarra"/>
                </div>
                <div>
                    <img src={Gallery7} alt="Maantietaulu"/>
                </div>
                <div>
                    <img src={Gallery8} alt="Pakettiauto"/>
                </div>
                <div>
                    <img src={Gallery9} alt="Popup"/>
                </div>
                <div>
                    <img src={Gallery10} alt="Rapala"/>
                </div>
                <div>
                    <img src={Gallery11} alt="Sahkokontti"/>
                </div>
                <div>
                    <img src={Gallery12} alt="Vene"/>
                </div>
            </Carousel>
            </div>
        </div>
    );
}