import './kaavaketta.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhoneSquare, faLocationDot  } from '@fortawesome/free-solid-svg-icons';
import { faInstagram, faFacebook } from "@fortawesome/free-brands-svg-icons";
import DynamiittiOrava from '../../assets/DynamiittiOrava.png'

// Animations
import { motion } from "framer-motion";
import { fromBelowAnim, fromBelowAnim2, fromBelowAnim3, fromBelowAnim4, fromBelowAnim5 } from "../../Animations";

const Kaavake = () =>{
      return(
        <div id="Contact" className="Contact">
            <motion.div 
                    variants={fromBelowAnim} 
                    initial="hidden"
                    whileInView="show"
                    viewport={{ once: true }}
                    className="Otsikkodiv-Otayht"
            >
                <h2 className="Otsikko Otsikko-Contact" id="Otayhteytta" >OTA YHTEYTTÄ</h2>
            </motion.div>
            <div className="flex-container">
                <div className="flex-item1"> 
                    <motion.div 
                        variants={fromBelowAnim2} initial="hidden" whileInView="show" viewport={{ once: true }}
                        className="Animation-ppp"
                    >
                        <p className="ppp">
                            Pyydä hinta-arviota
                            omalle ideallesi!
                        </p>
                    </motion.div>
                    <motion.div 
                        variants={fromBelowAnim3} initial="hidden" whileInView="show" viewport={{ once: true }}
                        className="Animation-ppp"
                    >
                        <p className="ppp">
                            Katsotaan yhdessä tarvetta ja millainen
                            printti sopii&nbsp;parhaiten.
                        </p>
                    </motion.div>
                    <motion.div 
                        variants={fromBelowAnim4} initial="hidden" whileInView="show" viewport={{ once: true }}
                        className="Animation-ppp"
                    >
                        <p className="ppp">
                            Tästä se lähtee!
                        </p>
                    </motion.div>
                </div>
                <div className="flex-item2">
                    <div className="flex-item2-contents">
                        <motion.div 
                            variants={fromBelowAnim} initial="hidden" whileInView="show" viewport={{ once: true }}
                            className="riville-container"
                        >
                            <span className="aaa">
                                <a href="mailto:mika.kuurne@grafiteam.fi" draggable="false">
                                    <FontAwesomeIcon icon={faEnvelope} className="fapic" />
                                    mika.kuurne@grafiteam.fi
                                </a>
                            </span>
                        </motion.div>
                        <motion.div 
                            variants={fromBelowAnim3} initial="hidden" whileInView="show" viewport={{ once: true }}
                            className="riville-container"
                        >
                            <span>
                                <a href="mailto:aineisto@grafiteam.fi" draggable="false">
                                    <FontAwesomeIcon icon={faEnvelope} className="fapic" />
                                    aineisto@grafiteam.fi
                                </a>
                            </span>
                        </motion.div>
                        <motion.div 
                            variants={fromBelowAnim5} initial="hidden" whileInView="show" viewport={{ once: true }}
                            className="riville-container"
                        >
                            <span>
                                <a href="tel:+358 40 577 8953" draggable="false">
                                    <FontAwesomeIcon icon={faPhoneSquare} className="fapic" />
                                    +358 40 577 8953
                                </a>
                            </span>
                        </motion.div>
                        <motion.div 
                            variants={fromBelowAnim5} initial="hidden" whileInView="show" viewport={{ once: true }}
                            className="riville-container"
                        >
                            <span>
                                <a href="https://www.google.com/search?q=Teollisuustie+6%2C+19650+Joutsa" draggable="false">
                                    <FontAwesomeIcon icon={faLocationDot} className="fapic" />
                                    Teollisuustie 6, 19650 Joutsa
                                </a>
                            </span>
                        </motion.div>
                    </div>
                </div>
            </div>
            <motion.div 
                    variants={fromBelowAnim2} initial="hidden" whileInView="show" viewport={{ once: true }}
                    className="rivitetty-container"
            >
                    <a href="https://www.instagram.com/grafiteam.fi" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faInstagram}
                         className="alaicon"/>
                    </a>
                    <a href="https://www.facebook.com/grafiteamoy/" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faFacebook}
                        className="alaicon"/>
                    </a>
                <div className="DynamiittiOrava">
                    <span className="testi"> © DynamiittiOrava <img src={DynamiittiOrava} alt="DynamiittiOrava logo"/></span>
                </div>
            </motion.div>
        </div>
    )
}

export default Kaavake;
